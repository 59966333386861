import React from 'react'
import Layout from '../../components/layout'
import { NamingsCreator } from '../../components/ChallengeCreators'

const NamingsCreatorPage = ({ challengeId }) => (
  <Layout>
    <NamingsCreator challengeId={challengeId} />
  </Layout>
)

export default NamingsCreatorPage
